import React from "react";
import { Link } from "react-router-dom";

interface Props {
  title: string;
  subtitle: string;
  company: string;
  tags: string;
  imageSrc: any;
  imageAlt: string;
  linkPath: string;
  fullWidthImage?: boolean;
  fullHeightImage?: boolean;
}

export default function PortfolioCard({
  title,
  subtitle,
  company,
  imageSrc,
  imageAlt,
  linkPath,
  fullWidthImage,
  fullHeightImage,
}: Props) {
  return (
    <Link to={linkPath} className="max-w-5xl mx-auto">
      <div className="md:h-[460px] group overflow-hidden transition-all rounded-3xl grid md:grid-cols-2 grid-cols-1 hover:scale-105 duration-300">
        <div className="md:p-10 p-4 h-full flex flex-col gap-6 justify-center col-span-1 md:order-1 order-2">
          <span className=" text-sm text-text-secondary font-medium tracking-wider">
            {company}
          </span>
          <div>
            <h2 className="font-display text-4xl font-bold text-text-primary mb-2 leading-tight text-balance">
              {title}
            </h2>
            <p className="text-text-secondary leading-7">{subtitle}</p>
          </div>

          <span className="text-text-accent group-hover:underline font-semibold underline-offset-4">
            Read case study
          </span>
        </div>
        <div className="md:h-full h-[400px] pt-4 md:pr-4 relative overflow-hidden col-span-1 md:order-2 order-1">
          <img
            src={imageSrc}
            alt={imageAlt}
            className={`${
              fullHeightImage ? "h-full" : "w-full"
            } mx-auto object-cover top-0 ${
              fullWidthImage ? "my-auto" : "rounded-2xl"
            } scale-90 group-hover:shadow-sm`}
          />
          <div className="absolute bottom-0 left-0 right-0 h-1/4 gradient-bg" />
        </div>
      </div>
    </Link>
  );
}
