import { useEffect } from "react";
import { useLocation } from "react-router-dom";

/* global gtag */
const useGoogleAnalytics = () => {
  const location = useLocation();

  useEffect(() => {
    if (localStorage.getItem("ignorePortfolioAnalytics") === "true") {
      console.log("Google Analytics disabled for this session.");
      return;
    }
    // Ensure the gtag function is available
    if (typeof gtag === "function") {
      gtag("config", "G-6TL2EB3Y3S", {
        page_title: location.pathname,
        page_location: window.location.href,
        page_path: location.pathname,
      });
    }
  }, [location]);
};

export default useGoogleAnalytics;
