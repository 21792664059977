import React from "react";
import PostTitle from "../components/PostTitle";
import PostLayout from "../components/PostLayout";

import vitalAssignmentGIF from "../assets/vital-assigment.gif";
import selectPODevice from "../assets/select-po-device.png";
import selectPatient from "../assets/select-patient.png";
import vitalCollectionHighFi from "../assets/vital-collection-high-fi.png";
import vitalCollectionPrototype from "../assets/vital-collection-prototype.gif";
import remoteMonitoringSketch from "../assets/patient-monitoring sketch.png";

import patientMonitoringHero from "../assets/patient-monitoring-hero.png";
import PostSectionTitle from "../components/PostSectionTitle";
import useGoogleAnalytics from "../googleAnalytics";

export default function PatientMonitoring() {
  useGoogleAnalytics();
  return (
    <PostLayout nextPath="/precision-hub">
      <PostTitle
        title="Using Design to Drive Product Direction"
        company="THOUGHTWIRE • Healthcare"
        time="2024"
        role="Product Design"
        output="Wireframes, High Fidelity Mockups, Prototype"
        small
      >
        I helped enhance Thoughtwire's Patient Monitoring Application to support
        facilities that use shared devices to collect patient vitals. Through
        rapid prototyping, I demonstrated how a mobile solution would better
        align with nursing workflows, successfully shifting our product
        direction.
      </PostTitle>

      <figure className="max-w-5xl mx-auto px-4 md:px-0">
        <img
          src={patientMonitoringHero}
          alt="Screenshot the desktop and mobile versions of the patient monitoring tool"
          className="my-24 md:w-full md:h-auto object-cover"
        />
      </figure>

      <section className="flex flex-col max-w-5xl mx-auto min-h-[60vh] justify-center p-4">
        <h2 className="uppercase text-text-tertiary font-medium">
          The Challenge
        </h2>
        <div className="grid md:grid-cols-2 grid-cols-1 gap-5 mt-4 mb-12">
          <div className="flex flex-col gap-4">
            <PostSectionTitle large>
              {"One device, Many residents"}
            </PostSectionTitle>
          </div>
          <div className="flex flex-col gap-4">
            <p>
              While our desktop application allowed clinicians to monitor
              patient vitals, we faced a new challenge: supporting retirement
              homes where multiple nurses share devices to collect resident
              vitals. The key requirements were to:
            </p>
            <ul className="list-disc ml-4">
              <li>Ensure we always knew which nurse took the vitals.</li>
              <li>Associate each vital with the right resident.</li>
            </ul>
            <figure>
              <img
                className="h-[200px] dark:invert dark:brightness-100 mx-auto"
                src={remoteMonitoringSketch}
                alt=""
              />
              <figcaption className="text-sm text-text-secondary mt-4 italic text-center">
                A sketch of the relationship between the relevant entities in
                patient monitoring.
              </figcaption>
            </figure>
          </div>
        </div>
      </section>
      <section className="flex flex-col max-w-5xl mx-auto min-h-[100vh] justify-center p-4 border-t-4 border-dashed border-t-bg-tertiary">
        <h2 className="uppercase text-text-tertiary font-medium">
          Initial Approach
        </h2>
        <div className="grid md:grid-cols-2 grid-cols-1 gap-5 mt-4 mb-12">
          <div className="flex flex-col gap-4">
            <PostSectionTitle large>
              Making it work with what we had
            </PostSectionTitle>
          </div>

          <div className="flex flex-col gap-4">
            <p>
              Our initial approach was to enhance the existing desktop
              application with a <em>vitals assignment tool</em> that would be
              used to assign vitals to patients after they had been taken.
              However, this solution would force nurses to first record vitals
              on paper (or the order in which they took vitals), then later make
              the assignments using a computer - creating double work and
              potential for errors.
            </p>
            <figure>
              <img
                className="md:hover:scale-150 transition-all rounded-lg hover:shadow-2xl hover:border-2 border-bg-accent"
                src={vitalAssignmentGIF}
                alt=""
              />
              <figcaption className="text-sm text-text-secondary mt-4 italic">
                An early desktop prototype for a vital assignment tool that
                would require nurses to assign vitals to patients after the
                vitals were taken.
              </figcaption>
            </figure>
          </div>
        </div>
      </section>

      <section className="flex flex-col max-w-5xl mx-auto min-h-[100vh] justify-center p-4 border-t-4 border-dashed border-t-bg-tertiary">
        <h2 className="uppercase text-text-tertiary font-medium">
          More Brainstorming
        </h2>
        <div className="grid md:grid-cols-2 grid-cols-1 gap-5 items-start mt-4 mb-12">
          <div className="flex flex-col justify-start gap-4 h-full">
            <PostSectionTitle large>
              Shifting our focus with a simple wireframe
            </PostSectionTitle>
          </div>
          <div className="flex flex-col gap-4">
            <p>
              While the initial solution addressed the technical challenges, it
              wasn't practical for nurses moving between rooms during rounds.
            </p>
            <p>
              To address this, I created wireframes for a mobile solution,
              focusing on the key tasks in the flow. This idea was well received
              by the team as it made the solution tangible, and shifted
              discussions from what was 'easiest' to build to what would truly
              improve the nurse's experience
            </p>
          </div>
        </div>
        <div className="min-h-72 flex gap-12">
          <figure>
            <img className=" mx-auto shadow-sm" src={selectPODevice} alt="" />
            <figcaption className="text-sm text-text-secondary mt-4 italic">
              Flow 1: Selecting a device
            </figcaption>
          </figure>
          <figure>
            <img className=" mx-auto shadow-sm" src={selectPatient} alt="" />
            <figcaption className="text-sm text-text-secondary mt-4 italic">
              Flow 2: Selecting a patient and collecting vitals
            </figcaption>
          </figure>
        </div>
      </section>
      <section className="flex flex-col max-w-5xl mx-auto min-h-[100vh] justify-center p-4 border-t-4 border-dashed border-t-bg-tertiary">
        <h2 className="uppercase text-text-tertiary font-medium">
          Bringing it all together
        </h2>
        <div className="grid md:grid-cols-2 grid-cols-1 gap-5 items-center mt-4 mb-12">
          <div className="flex flex-col gap-4">
            <PostSectionTitle large>
              A 3-step process for collecting vitals
            </PostSectionTitle>
          </div>
          <div className="flex flex-col gap-4">
            <p>
              As the design evolved, we prioritized simplicity. One key change
              was replacing QR codes with simple lists for selecting devices and
              patients. This made the process more intuitive and accessible
              while ensuring discussions remained focused on user needs rather
              than technical complexities.
            </p>
          </div>
        </div>
        <div className="min-h-72 rounded-2xl flex gap-12">
          <figure>
            <img
              className="mx-auto shadow-sm"
              src={vitalCollectionHighFi}
              alt=""
            />
            <figcaption className="text-sm text-text-secondary mt-4 italic">
              {`The final design simplified the process into three clear steps: choose a device, select a patient, and collect vitals - with real-time feedback on the mobile device.`}
            </figcaption>
          </figure>
        </div>
      </section>
      <section className="flex flex-col max-w-5xl mx-auto min-h-[100vh] justify-center p-4 border-t-4 border-dashed border-t-bg-tertiary">
        <h2 className="uppercase text-text-tertiary font-medium">Impact</h2>
        <div className="grid md:grid-cols-2 grid-cols-1 gap-5 mt-4 mb-12">
          <div className="flex flex-col gap-4">
            <PostSectionTitle large>"...it's simple..."</PostSectionTitle>
            <p>
              ...was a reaction from our clinical stakeholders that stood out as
              it captured the essence of the solution. The prototype didn't just
              address the problem, but its simplicity also brought clarity,
              helping the team and stakeholders align on a truly user-centered
              solution.
            </p>
            <p>
              I particularly loved how it accelerated our decision-making,
              replacing hours of abstract discussions with a shared
              understanding and clear path forward.
            </p>
          </div>
          <div className="flex flex-col gap-4">
            <figure>
              <img
                className="h-[600px] mx-auto shadow-sm rounded-3xl border-4 border-black"
                src={vitalCollectionPrototype}
                alt=""
              />
              <figcaption className="text-sm text-text-secondary mt-4 italic text-center">
                Final prototype of the vital collection mobile application
              </figcaption>
            </figure>
          </div>
        </div>
      </section>
    </PostLayout>
  );
}
