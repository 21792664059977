import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import PrecisionHub from "./pages/precision-hub";
import Home from "./pages/home";
import ScrollToTop from "./components/ScrolltoTop";
import AssignmentTool from "./pages/assignment-tool";
import CommentPreviews from "./pages/comment-previews";
import PatientMonitorint from "./pages/patient-monitoring";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route
          path="assignment-tool"
          element={<AssignmentTool />}
        />
        <Route path="precision-hub" element={<PrecisionHub />} />
        <Route path="comment-previews" element={<CommentPreviews />} />
        <Route path="patient-monitoring" element={<PatientMonitorint />} />
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
