import React from "react";

interface Props {
  company: string;
  time: string;
  title: string;
  role: string;
  output?: string;
  children?: any;
  small?: boolean
}

export default function PostTitle({
  company,
  time,
  title,
  role,
  output,
  children,
  small
}: Props) {
  return (
    <div className={`${small ? "max-w-5xl": "container"} mx-auto p-4 md:pt-12`}>
      <div className="max-w-3xl text-balance">
        <div className="flex flex-col gap-2">
          <div className="flex gap-2 text-text-secondary font-medium pb-6 tracking-wider">
            <span className=""> {company} • {time}</span>
          </div>
          <h1 className="font-display md:text-6xl md:leading-tight text-4xl font-bold text-balance">{title}</h1>
          <div className="pb-6">
            <div className="flex flex-col justify-center">
              <div className="md:flex gap-2 text-text-secondary">
                <span className="w-16 font-semibold">Role</span>{" "}
                <span className="">{role}</span>
              </div>
              {!!output && <div className="md:flex gap-2 text-text-secondary">
                <span className="w-16 text-text-secondary font-semibold">
                  Output
                </span>{" "}
                <span className="">{output}</span>
              </div>}
            </div>
          </div>
          {children}
        </div>
      </div>
    </div>
  );
}
